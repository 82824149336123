<template>
  <div class="d-flex filter" >
    <div>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="formSearch.search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    <div class="ml-5">
      <DateRangeComponent v-model="formSearch.ngay_hoach_toan" />
    </div>
    <div class="ml-5">
      <v-text-field
        v-model="formSearch.so_chung_tu"
        label="Số chứng từ"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    <div class="ml-5">
      <v-autocomplete
        v-model="formSearch.kho"
        :items="khos"
        outlined
        dense
        placeholder="Kho"
        item-text="name"
        item-value="id"
        hide-details
      >
      </v-autocomplete>
    </div>
  </div>
</template>
<script>
import DateRangeComponent from "@/components/Date/date-range";
import { getDanhMucCon } from "@/api/danhmuc.js";
export default {
  props: {
    value: { type: Object, default: () => ({}) },
  },
  components: {
    DateRangeComponent,
  },
  data: () => ({
    khos: [],
  }),
  computed: {
    formSearch: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.fetchKho();
  },
  methods: {
    async fetchKho() {
      const res = await getDanhMucCon({ code: "DANHSACHKHO" });
      this.khos = res;
    },
  },
};
</script>
<style scoped>
.filter{
  margin-bottom: 10px;
}
</style>