<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="2000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ customer_name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <FilterComponent v-model="formSearch" />
            </v-row>
            <v-row>
              <CustomTable
                :headers="headers"
                :items="tableData"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                :loading="isloading"
                :pageCount="pageCount"
                @change-page="changePage"
                loading-text="Đang tải dữ liệu ..."
                classPaging="pt-2"
              >
                <template v-slot:[`item.nha_cung_cap`]="{ item }">
                  <div v-if="item.nha_cung_cap">
                    <strong>{{ `${item.nha_cung_cap.code} -` }}</strong>
                    {{ ` ${item.nha_cung_cap.name}` }}
                  </div>
                </template>
                <template v-slot:[`item.hang_hoa`]="{ item }">
                  <div v-if="item.hang_hoa">
                    <strong>{{ `${item.hang_hoa.code} -` }}</strong>
                    {{ ` ${item.hang_hoa.name}` }}
                  </div>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <div>{{ handleType(item.type) }}</div>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <div>{{ handleDateMonth(item.date) }}</div>
                </template>
                <template v-slot:[`item.gia_tri_mua`]="{ item }">
                  <div>{{ handleNumber(item.gia_tri_mua) }}</div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span>{{ handleShowTextStatus(item.status) }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="textColor">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="handle_exportBbnt(item)">
                        <v-list-item-title>
                          <v-icon class="mr-2"> mdi-microsoft-word </v-icon>
                          Xuất đề nghị thanh toán</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </CustomTable>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="handle_export">
            <v-icon> mdi-microsoft-excel </v-icon>
            Xuất excel
          </v-btn>
          <v-btn color="default" @click="exit()"> Thoát </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import BBBG from "@/api/bienbangiaonhan.api";
import { TYPES, STATUS } from "@/constants/bienbangiaonhan";
import { numberFormat } from "@/utils/moneyFormat";
import FilterComponent from "./filter";
import { saveAs } from "file-saver";
import API from "@/api/bienbannghiemthu.api";
export default {
  components: { FilterComponent },
  data: () => ({
    dialog: false,
    isloading: false,
    btnloading: false,
    formSearch: {
      customer_id: null,
    },
    headers: [
      {
        text: "Ngày hoạch toán",
        value: "ngay_hoach_toan",
        sortable: false,
      },
      {
        text: "Số chứng từ",
        value: "so_chung_tu",
        sortable: false,
      },
      {
        text: "Trạng thái",
        value: "status",
        sortable: false,
      },
      {
        text: "Nhà cung cấp",
        value: "nha_cung_cap.name",
        sortable: false,
      },
      { text: "Kiểu", value: "type", width: "80px", sortable: false },
      { text: "Kho", value: "kho.name", width: "200px", sortable: false },
      // { text: "Mã hàng", value: "hang_hoa.code" },
      {
        text: "Hàng hoá",
        value: "hang_hoa.name",
        sortable: false,
      },
      { text: "SL", value: "so_luong", width: "100px", sortable: false },
      { text: "Đơn giá", value: "don_gia", width: "100px", sortable: false },
      {
        text: "Giá trị mua",
        value: "gia_tri_mua",
        sortable: false,
      },
      { text: "ĐVT", value: "hang_hoa.unit", width: "50px", sortable: false },
      {
        text: "Chiết khấu",
        value: "chiet_khau",
        sortable: false,
      },
      {
        text: "SL trả lại",
        value: "so_luong_tra_lai",
        sortable: false,
      },
      {
        text: "GT trả lại",
        value: "gia_tri_tra_lai",
        sortable: false,
      },
      {
        text: "GT giảm giá",
        value: "gia_tri_giam_gia",
        sortable: false,
      },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "100",
      },
    ],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    tableData: [],
    customer_name: "",
    customer_code: "",
  }),
  methods: {
    changePage(val) {
      this.page = val;
      this.getlist();
    },
    show(data) {
      this.dialog = true;
      if (
        Object.keys(data.customer_id).length != 0 &&
        data.customer_id.nha_cung_cap_id
      ) {
        this.formSearch.customer_id = data.customer_id.nha_cung_cap_id;
      } else {
        this.formSearch.customer_id = data.customer_id;
      }
      this.getlist();
      this.customer_name = data.customer_name;
      this.customer_code = data.customer_code;
    },
    async getlist() {
      this.isloading = true;
      const res = await BBBG.detailBbnt({
        page: this.page,
        perPage: this.itemsPerPage,
        ...this.formSearch,
      });
      this.tableData = res.data.data;
      this.pageCount = res.data.last_page;
      this.isloading = false;
    },
    async handle_export() {
      this.isloading = true;
      const ex = await BBBG.exportDetailBbnt(this.formSearch);
      if (ex && ex.isDone) {
        saveAs(
          new Blob([ex.data]),
          "Bienbanbannghiemthu" + "-" + this.customer_code + ".xlsx"
        );
      }
      this.isloading = false;
    },
    exit() {
      this.$emit("on-done");
      this.formSearch = {
        customer_id: null,
        kho: null,
        ngay_hoach_toan: [],
        search: null,
        so_chung_tu: null,
      };
      this.dialog = false;
      this.tableData =[];
    },
    handleNumber(number) {
      return numberFormat(number);
    },
    handleType(type) {
      let find = TYPES.find((x) => x.id == type);
      return find ? find.name : "";
    },
    handleDateMonth(date) {
      let arr = date.split("-");
      return `${arr[1]}/${arr[0]}`;
    },
    handleShowTextStatus(id) {
      let find = STATUS.find((x) => x.id == id);
      if (find) {
        return find.name;
      }
      return "";
    },
    handle_exportBbnt(item) {
      this.$confirmBox.show({
        title: "Thông báo ",
        width: 500,
        body: "Xác nhận xuất biên bản nghiệm thu ",
        action: async () => {
          var dateObj = new Date();
          var month = dateObj.getUTCMonth() + 1;
          var day = dateObj.getUTCDate();
          var year = dateObj.getUTCFullYear();
          let newdate = day + "/" + month + "/" + year;
          const res = await API.exportBienBanChungtu(item.id);
          if (res && res.isDone) {
            saveAs(
              new Blob([res.data]),
              `Bienbannghiemthu${item.code + "-" + newdate}.docx`
            );
          }
        },
        onDone: () => this.getAllList(),
      });
    },
  },
  watch: {
    formSearch: {
      deep: true,
      handler: function () {
        this.page = 1;
        this.getlist();
      },
    },
  },
};
</script>