var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"2000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.customer_name))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('FilterComponent',{model:{value:(_vm.formSearch),callback:function ($$v) {_vm.formSearch=$$v},expression:"formSearch"}})],1),_c('v-row',[_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.isloading,"pageCount":_vm.pageCount,"loading-text":"Đang tải dữ liệu ...","classPaging":"pt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.nha_cung_cap",fn:function(ref){
var item = ref.item;
return [(item.nha_cung_cap)?_c('div',[_c('strong',[_vm._v(_vm._s(((item.nha_cung_cap.code) + " -")))]),_vm._v(" "+_vm._s((" " + (item.nha_cung_cap.name)))+" ")]):_vm._e()]}},{key:"item.hang_hoa",fn:function(ref){
var item = ref.item;
return [(item.hang_hoa)?_c('div',[_c('strong',[_vm._v(_vm._s(((item.hang_hoa.code) + " -")))]),_vm._v(" "+_vm._s((" " + (item.hang_hoa.name)))+" ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.handleType(item.type)))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.handleDateMonth(item.date)))])]}},{key:"item.gia_tri_mua",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.handleNumber(item.gia_tri_mua)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.handleShowTextStatus(item.status)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handle_exportBbnt(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-microsoft-word ")]),_vm._v(" Xuất đề nghị thanh toán")],1)],1)],1)],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.handle_export}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Xuất excel ")],1),_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){return _vm.exit()}}},[_vm._v(" Thoát ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }