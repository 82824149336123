import request from "../utils/request";
import { AApi } from "@/service/AApi";
const API = new AApi(
  {},
  {
    exportBienBan: {
      async request(params) {
        return this.sdk({
          url: "/bienbannghiemthu-export",
          params,
          responseType: 'blob'
        });
      },
    },
    exportBienBanChungtu: {
      async request(params) {
        return this.sdk({
          url: "/bienbannghiemthu-chungtu-export",
          responseType: "blob",
          params
        });
      },
    },
  }

)
export default API;
