<template>
  <div>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :items-per-page=itemsPerPage
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Xem chi tiết</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <Detail ref="form" @on-done="getAllList"></Detail>
  </div>
</template>
<script>
// import CreateEdit from "./DialogCreateEdit";
import { numberFormat } from "@/utils/moneyFormat";
import { saveAs } from "file-saver";
import API from "@/api/bienbannghiemthu.api";
import BBGN from "@/api/bienbangiaonhan.api";
import Detail from './detail.vue';

export default {
  components: { Detail },
  props: {
    isShowListTotal: Boolean,
    customer_id: { type: [Number, String], default: null },
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "STT",
        value: "stt",
        width: "70px",
        sortable: false,
      },
      {
        text: "Mã nhà cung cấp",
        value: "customer_code",
        width: "200px",
        sortable: false,
      },
      {
        text: "Nhà cung cấp",
        value: "customer_name",
        width: "200px",
        sortable: false,
      },
      {
        text: "Tổng giá trị mua",
        value: "sum_cost",
        width: "150px",
        sortable: false,
      },
      {
        text: "Tổng giá trị chiết khấu",
        value: "sum_costck",
        width: "150px",
        sortable: false,
      },
      {
        text: "Tổng giá trị trả lại",
        value: "sum_costtl",
        width: "100px",
        sortable: false,
      },
      {
        text: "Tổng giá trị giảm giá",
        value: "sum_costgg",
        width: "150px",
        sortable: false,
      },
      {
        text: "Hành động",
        value: "action",
        sortable: false,
        align: "center",
        width: "100",
      },
    ],
    tableData: [],
    itemsPerPage: 30
  }),
  computed: {},
  created() {
    this.getAllList();
  },
  watch: {
    // this.$emit("table-reset-filter");
  },
  methods: {
    async getAllList(p = {}) {
      try {
        const res = await BBGN.indexBbnt(p);
        res.data.forEach((e) => {
          e.sum_cost = this.handleNumber(e.sum_cost);
          e.sum_costck = this.handleNumber(e.sum_costck);
          e.sum_costtl = this.handleNumber(e.sum_costtl);
          e.sum_costgg = this.handleNumber(e.sum_costgg);
        });
        this.tableData = res.data;
      } finally {
        this.loading = false;
      }
    },
    handleNumber(number) {
      return numberFormat(number);
    },

    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    async convertBBNT(item) {
      let data = { status: 1 };
      this.$confirmBox.show({
        title: "Chuyển biên bản giao nhận",
        width: 500,
        body: "Xác nhận chuyển biên bản giao nhận",
        action: async () => await BBGN.update(item.id, data),
        onDone: () => this.getAllList(),
      });
    },
    editItem(item) {
      this.$refs.form.show(item);
    },
    handle_exportBbnt(item) {
      this.$confirmBox.show({
        title: "Thông báo ",
        width: 500,
        body: "Xác nhận đề nghị thanh toán cho khách hàng : "+item.code+"-"+item.nha_cung_cap.name,
        action: async () => {
          var dateObj = new Date();
          var month = dateObj.getUTCMonth() + 1;
          var day = dateObj.getUTCDate();
          var year = dateObj.getUTCFullYear();
          let newdate = day + "/" + month + "/" + year;
          const res = await API.exportBienBanChungtu(item.id);
          if (res && res.isDone) {
            saveAs(
              new Blob([res.data]),
              `Bienbannghiemthu${item.code + "-" + newdate}.docx`
            );
          }
        },
        onDone: () => this.getAllList(),
      });
    },
  },
};
</script>